import React, { useContext, useState } from 'react';

function Znamkovani() {
  return (
      <section id="materialy" className="section znamkovani">
      <h2>Známkování</h2>
      <div className="bold"><p>2. pololetí</p></div>
      <p>Ve druhém pololetí vám dám známku na vysvědčení za splnění nějakého projektu dle vašeho výběru, který bude souviset s informatikou.</p>
      <p>Téma si musíte nechat schválit do 24. února, ale nemusí jít o nic složitého – stačí něco, co byste zvládli zhruba za jeden den a bude vás to bavit.</p>
      <p>Projekt musíte dokončit a odevzdat do 7. dubna.</p>
      <p>Alternativně můžete během pololetí plnit průběžné úkoly, nebo na konci napsat test z machine learningu, kterému se budeme věnovat.</p>
      </section>
  );
}
export default Znamkovani;
