import React, { useContext, useState } from 'react';
import './styles.css';

function Materialy() {
  return (
    <section id="materialy" className="section materialy">
      <h2>Materiály</h2>
      <a href="/pruvodce_labyrintem_algoritmu.pdf" className="button-materialy">Průvodce labyrintem algoritmů</a>
      <a href="https://nextcloud.stepankovar.cz/s/cnBfENo4MBjNWaN" className="button-materialy">Kapitoly z diskrétní matematiky</a>
      <a href="https://nextcloud.stepankovar.cz/s/9QrKKwyAECCRSbt" className="button-materialy">Pattern recognition and machine learning</a>
    </section>
  );
}
export default Materialy;
